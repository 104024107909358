<template>
    <div>
      <CCard accentColor="primary">
        <CCardHeader>
          <slot name="header">
            <CIcon name="cil-grid" />
            {{title}}
          </slot>
        </CCardHeader>
        <CCardBody>
          <CRow v-show="!formVisibility">
            <!-- GANTI UNIT KERJA KE OPD PAKE VUE SELECT -->
            <CCol md="12">
              <div role="group" class="form-group" v-if="user.role.superadmin == 1">
                <label>OPD</label>
                <vue-select
                placeholder="Nama OPD"
                v-model="form.nama_unit_kerja"
                :options="optionsOPD"
                label="nama_opd"
                :reduce="a => a.nama_opd"
                :filterable="true"
                @search="onSearchOPD"
                ></vue-select>
              </div>
              <CInput label="No Usulan" placeholder="No Usulan" v-model="form.no_surat"></CInput>
            </CCol>
          </CRow>
          <CButtonGroup size="sm">
            <CButton color="info" v-show="!formVisibility" @click="loadData()">
              <CIcon name="cil-search" />Cari
            </CButton>
            <CButton color="warning" v-show="!formVisibility" @click="reset()">
              <CIcon name="cil-loop-circular" />Reset
            </CButton>
          </CButtonGroup>
          <hr />
          <CCollapse :show="formVisibility">
            <form-input @done="resetTabel" @clicked="onClickChild" ref="formInput" />
          </CCollapse>
          <CDataTable
            :items="computedItems"
            :fields="fields"
            hover
            small
            border
            v-show="!formVisibility"
            :loading="loading"
          >
            <template #show_details="{item, index}">
              <td class="py-2">
                <CButton
                  color="primary"
                  variant="outline"
                  square
                  size="sm"
                  class="ml-1 mt-1"
                  @click="toggleDetails(item, false)"
                  v-if="
                    item.tracking_layanan.length > 0 &&
                    (item.tracking_layanan[item.tracking_layanan.length - 1].status == 3 && item.tracking_layanan[item.tracking_layanan.length - 1].status_hasil == 0)"
                  >{{Boolean(item._toggled) ? 'Tutup' : 'Verifikasi'}}</CButton
                >
                <CButton
                  color="primary"
                  variant="outline"
                  square
                  size="sm"
                  class="ml-1 mt-1"
                  @click="toggleDetails(item, true)"
                  v-else
                  >View</CButton
                >
                <CButton
                    color="warning"
                    variant="outline"
                    square
                    size="sm"
                    class="ml-1 mt-1"
                    @click="viewTracking(item, index)"
                    >Tracking</CButton
                >
              </td>
            </template>
          </CDataTable>
          <CPagination
            :activePage.sync="page"
            :pages.sync="totalPage"
            size="sm"
            align="end"
            v-show="!formVisibility"
          />
        </CCardBody>
      </CCard>
      <modal-tracking ref="modalViewTracking" />
    </div>
</template>
<script>
  import { VueSelect } from "vue-select";
  import formInput from "./form";
  import _ from "lodash";
  import modalTracking from "../TrackingLayanan/ModalViewTracking";
  import { mapGetters } from "vuex";

  const fields = [
    { key: "number", label: "No" },
    { key: "no_surat", label: "No. Usulan" },
    { key: "tgl_surat", label: "Tanggal Usulan" },
    { key: "nama_unit_kerja", label: "OPD" },
    { key: "jumlah_usulan", label: "Jumlah yang Diusulkan" },
    { key: "status", label: "Status" },
    {
      key: "show_details",
      label: "Action",
      sorter: false,
      filter: false,
    },
  ];
  export default {
    name: "VerifikasiPenerbitanSKPensiun",
    components: {
    formInput,
    VueSelect,
    modalTracking
  },
    data() {
      return {
        items: [],
        fields,
        details: [],
        collapseDuration: 0,
        formVisibility: false,
        loading: false,
        page: 1,
        totalPage: 0,
        optionsInstansi: [],
        optionsUnitKerja: [],
        optionsOPD: [],
        optionsJabatanUmum: [],
        form: {
          nama_unit_kerja: null,
          no_surat: null,
          jenis_layanan_general: 'pensiun'
        },
      };
    },
    watch: {
      page: function () {
        this.loadData();
      },
      formVisibility: function (val) {
        if (!val) {
          this.$refs.formInput.populateInput(null);
        } else {
          this.loadData();
        }
      },
    },
    computed: {
      ...mapGetters("auth", { user: "user" }),
      computedItems() {
        var number = 1;
        let total_pages = 10 * (this.page - 1);
        return this.items.map((item) => {
          var status_tindakan = "";
          var status_hasil = "";
          if (item.tracking_layanan.length > 0) {
            var lastRowLayanan = item.tracking_layanan[item.tracking_layanan.length - 1];
            if (lastRowLayanan.status == 3) {
              status_tindakan = "Verifikasi I";
              if (lastRowLayanan.status_hasil == 0) {
                status_hasil = "Belum"
              } else if (lastRowLayanan.status_hasil == 1) {
                status_hasil = "Disetujui"
              } else if (lastRowLayanan.status_hasil == 2) {
                status_hasil = "Berkas Tidak Lengkap"
              } else if (lastRowLayanan.status_hasil == 3) {
                status_hasil = "Tidak Memenuhi Syarat"
              }
            } else if (lastRowLayanan.status == 4) {
              status_tindakan = "Verifikasi II";
              if (lastRowLayanan.status_hasil == 0) {
                status_hasil = "Belum"
              } else if (lastRowLayanan.status_hasil == 1) {
                status_hasil = "Disetujui"
              } else if (lastRowLayanan.status_hasil == 2) {
                status_hasil = "Berkas Tidak Lengkap"
              } else if (lastRowLayanan.status_hasil == 3) {
                status_hasil = "Tidak Memenuhi Syarat"
              }
            } else if (lastRowLayanan.status == 5) {
              status_tindakan = "Pengesahan";
              if (lastRowLayanan.status_hasil == 0) {
                status_hasil = "Belum"
              } else if (lastRowLayanan.status_hasil == 1) {
                status_hasil = "Disetujui"
              } else if (lastRowLayanan.status_hasil == 2) {
                status_hasil = "Berkas Tidak Lengkap"
              } else if (lastRowLayanan.status_hasil == 3) {
                status_hasil = "Tidak Memenuhi Syarat"
              }
            }
          }
          return {
            ...item,
            number: number++ + total_pages,
            nama_unit_kerja: item.mst_unit_kerja.nama_unit_kerja,
            jumlah_usulan: item.pelayanan_detail.length,
            status: status_hasil != '' ? status_tindakan + ' (' + status_hasil + ')' : '',
          };
        });
      },
      title() {
        return this.formVisibility
          ? "Form Verifikasi Penerbitan SK Pensiun"
          : "Tabel Verifikasi Penerbitan SK Pensiun";
      },
    },
    mounted() {
      this.loadData();
    },
    methods: {
      reset() {
        this.form.nama_unit_kerja = null;
        this.form.no_surat = null;
        this.page = 1;
        this.loadData();
      },
      async loadData() {
        try {
          this.loading = true;
          let data = await this.$store.dispatch("pelayanan/indexVerifikasiPenerbitanSkPensiunI", {
            page: this.page,
            data: this.form,
          });
          this.items = data.data;
          this.totalPage = data.last_page;
        } catch (ex) {
          this.items = [];
        } finally {
          this.loading = false;
        }
      },
      resetTabel() {
        this.$refs.formInput.populateInput(null);
        this.formVisibility = false;
        this.loadData();
      },
      toggleDetails(item, formDisabled) {
        // this.$set(this.items[index], "_toggled", !item._toggled);
        this.formVisibility = true;
        this.$refs.formInput.populateInput(item, formDisabled);
        this.collapseDuration = 300;
        this.$nextTick(() => {
          this.collapseDuration = 0;
        });
      },
      openNewForm() {
        this.formVisibility = !this.formVisibility;
        this.$refs.formInput.populateInput(null);
      },
      onClickChild() {
        this.formVisibility = !this.formVisibility;
        this.$refs.formInput.populateInput(null);
      },
      async onSearchOPD(search, loading) {
        loading(true);
        await this.searchOPD(search, this);
        loading(false);
      },
      searchOPD: _.debounce(async (search, vm) => {
        let options = await vm.$store.dispatch(
          "kualifikasi_jabatan_struktural/autocomplete_opd",
          search
        );
        if (search) vm.optionsOPD = options;
      }, 300),
      viewTracking(item) {
        item.modalTitle = 'Penerbitan SK Pensiun'; // please change this according to each menu
        this.$refs.modalViewTracking.toggle(item);
      },
    },
  };
  </script>