<template>
  <div>
    <CCard accentColor="primary">
      <CCardBody>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <CForm @submit.stop.prevent="handleSubmit(submit)">
            <CRow>
              <CCol md="3">
                <validation-provider
                  rules="required"
                  v-slot="{ errors }"
                  name="No. Usulan"
                >
                  <CInput
                    label="No. Usulan"
                    placeholder="No. Usulan"
                    v-model="form.no_surat"
                    :invalid-feedback="errors[0]"
                    :isValid="!errors[0]"
                    readonly
                  ></CInput>
                </validation-provider>
              </CCol>
              <CCol md="3">
                <validation-provider
                  rules="required"
                  v-slot="{ errors }"
                  name="Tanggal Usulan"
                >
                  <CInput
                    label="Tanggal Usulan"
                    placeholder="Tanggal Usulan"
                    v-model="form.tgl_surat"
                    :invalid-feedback="errors[0]"
                    :isValid="!errors[0]"
                    readonly
                  ></CInput>
                </validation-provider>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <validation-provider
                  rules="required"
                  v-slot="{ errors }"
                  name="Instansi"
                >
                  <div role="group" class="form-group">
                    <label class>Instansi</label>
                    <v-select
                      v-model="form.id_instansi"
                      :options="optionsInstansi"
                      label="nama_instansi"
                      :reduce="(instansi) => instansi.id_instansi"
                      :filterable="false"
                      @search="onSearchInstansi"
                      :disabled="true"
                      :class="[
                        { 'is-valid': !errors[0] },
                        { 'is-invalid': errors[0] },
                      ]"
                    ></v-select>
                    <div class="invalid-feedback" v-if="errors[0]">
                      {{ errors[0] }}
                    </div>
                  </div>
                </validation-provider>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <validation-provider
                  rules="required"
                  v-slot="{ errors }"
                  name="OPD"
                >
                  <div role="group" class="form-group">
                    <label class>OPD</label>
                    <v-select
                      v-model="form.id_unit_kerja"
                      :options="optionsUnitKerja"
                      label="nama_unit_kerja"
                      :reduce="(unit_kerja) => unit_kerja.id_unit_kerja"
                      :filterable="false"
                      @search="onSearchUnitKerja"
                      :disabled="true"
                      :class="[
                        { 'is-valid': !errors[0] },
                        { 'is-invalid': errors[0] },
                      ]"
                    ></v-select>
                    <div class="invalid-feedback" v-if="errors[0]">
                      {{ errors[0] }}
                    </div>
                  </div>
                </validation-provider>
              </CCol>
            </CRow>
            <hr />
            <template v-for="(data, index) in form.details">
              <fieldset
                class="form-group border p-3"
                :key="index"
                v-if="data.is_deleted == 0"
              >
                <div small style :key="componentKey">
                  <CRow>
                    <CCol md="6">
                      <validation-provider
                        rules="required"
                        v-slot="{ errors }"
                        name="NIP - Nama"
                      >
                        <div role="group" class="form-group">
                          <label class>NIP - Nama</label>
                          <v-select
                            :key="'select_' + componentKey + index"
                            v-model="data.petugas_array"
                            :options="data.optionsPgw"
                            label="nama"
                            :filterable="false"
                            @search="onSearch(index, $event)"
                            @input="onChangesPegawai(index, $event)"
                            :disabled="true"
                            :class="[
                              { 'is-valid': !errors[0] },
                              { 'is-invalid': errors[0] },
                            ]"
                          ></v-select>
                          <div class="invalid-feedback" v-if="errors[0]">
                            {{ errors[0] }}
                          </div>
                        </div>
                      </validation-provider>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="6">
                      <validation-provider
                        rules="required"
                        v-slot="{ errors }"
                        name="NIP - Nama"
                      >
                        <div role="group" class="form-group">
                          <label class>Jenis Pensiun</label>
                          <v-select
                            v-model="data.jenis_pelayanan_array"
                            :options="optionsJenisLayanan"
                            label="nama_layanan"
                            :filterable="true"
                            @input="onChangesJenisLayanan(index, $event)"
                            :disabled="true"
                            :class="[
                              { 'is-valid': !errors[0] },
                              { 'is-invalid': errors[0] },
                            ]"
                          ></v-select>
                          <div class="invalid-feedback" v-if="errors[0]">
                            {{ errors[0] }}
                          </div>
                        </div>
                      </validation-provider>
                    </CCol>
                  </CRow>
                  <!---->
                  <fieldset
                    class="form-group border p-3"
                    v-if="data.informasi_pegawai"
                  >
                    <legend class="w-auto px-2">Informasi Pegawai</legend>

                    <CRow>
                      <CCol md="6">
                        <CInput
                          label="Jenis Kelamin"
                          placeholder
                          readonly
                          v-model="data.informasi_pegawai.jenis_kelamin"
                        ></CInput>
                        <CInput
                          label="Jabatan"
                          placeholder
                          readonly
                          v-model="data.informasi_pegawai.nama_jabatan"
                        ></CInput>
                        <CInput
                          label="Nama Unit Kerja"
                          placeholder
                          readonly
                          v-model="data.informasi_pegawai.nama_unit_kerja"
                        ></CInput>
                        <CInput
                          label="Golongan Ruang"
                          placeholder
                          readonly
                          v-model="
                            data.informasi_pegawai.pangkat_golongan_ruang
                          "
                        ></CInput>
                      </CCol>
                      <CCol md="6">
                        <CInput
                          label="Gaji Pokok"
                          placeholder
                          readonly
                          v-model="data.informasi_pegawai.gaji_pokok"
                        ></CInput>
                        <CInput
                          label="TMT Pangkat"
                          placeholder
                          readonly
                          v-model="data.informasi_pegawai.tmt_pangkat"
                        ></CInput>
                        <CInput
                          label="Masa Jabatan"
                          placeholder
                          readonly
                          v-model="data.informasi_pegawai.masa_jabatan"
                        ></CInput>
                        <CInput
                          label="Umur"
                          placeholder
                          readonly
                          v-model="data.informasi_pegawai.umur"
                        ></CInput>
                      </CCol>
                    </CRow>
                  </fieldset>
                  <div class="position-relative table-responsive">
                    <table class="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <th
                            class
                            style="vertical-align: middle; overflow: hidden"
                          >
                            <div>No</div>
                            <!---->
                          </th>
                          <th
                            class
                            style="vertical-align: middle; overflow: hidden"
                          >
                            <div>Jenis Dokumen</div>
                            <!---->
                          </th>
                          <th
                            class
                            style="vertical-align: middle; overflow: hidden"
                          >
                            <div>Format File</div>
                            <!---->
                          </th>
                          <th
                            class
                            style="vertical-align: middle; overflow: hidden"
                          >
                            <div>File Upload</div>
                            <!---->
                          </th>
                        </tr>
                        <!---->
                      </thead>
                      <tbody class="position-relative">
                        <tr
                          v-for="(dataUpload, index2) in data.upload_detail"
                          :key="index2"
                        >
                          <td>{{ index2 + 1 }}</td>
                          <td>{{ dataUpload.nama_dokumen }}</td>
                          <td>{{ dataUpload.format_file }}</td>
                          <td style="width: 15%">
                            <CInputFile
                              label="File input"
                              @change="uploadFile($event, index, index2)"
                              :isValid="true"
                              custom
                              v-if="
                                !dataUpload.upload_dokumen &&
                                !dataUpload.loadingFile
                              "
                            />
                            <CSpinner
                              color="success"
                              size="sm"
                              v-if="dataUpload.loadingFile"
                            />
                            <CButtonGroup
                              size="sm"
                              v-if="dataUpload.upload_dokumen"
                            >
                              <CButton
                                color="success"
                                class="px-4"
                                @click="downloadFile(index, index2)"
                                >Download File</CButton
                              >
                            </CButtonGroup>
                          </td>
                        </tr>
                      </tbody>
                      <!---->
                    </table>
                    <!---->
                  </div>
                  <!---->
                </div>
              </fieldset>
            </template>
            <CRow>
              <CCol md="6">
                <CTextarea
                  label="Catatan"
                  :placeholder="formDisabled ? '' : 'Catatan'"
                  rows="3"
                  v-model="form.keterangan"
                  :disabled="formDisabled"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="5">
                <CSelect
                  label="Verifikasi"
                  :options="optionsStatusVerifikasi"
                  placeholder="Pilih Hasil Verifikasi"
                  :value.sync="form.status_hasil"
                  :disabled="formDisabled"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol col="6" class="text-left">
                <CButton color="success" class="px-4" @click="CloseForm()">
                Kembali</CButton>
                <CSpinner color="primary" size="sm" v-if="loading" />
                &nbsp;
                <CButton
                  class="px-4"
                  color="primary"
                  @click="verifikasiKenaikan()"
                  v-if="!formDisabled"
                  >Verifikasi</CButton>
              </CCol>
              <CCol col="6" class="text-right">
                <!-- <CButton color="link" class="px-0">Forgot password?</CButton>
                <CButton color="link" class="d-lg-none">Register now!</CButton>-->
              </CCol>
            </CRow>
          </CForm>
        </ValidationObserver>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
  import Swal from "sweetalert2";
  import VSelect from "vue-select";
  import _ from "lodash";
  import axios from "axios";
  
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  export default {
    name: "FormPenerbitanSkPensiun",
    components: {
      VSelect,
      ValidationProvider,
      ValidationObserver,
    },
    data() {
      return {
        errorMessage: null,
        optionsPgw: [],
        optionsInstansi: [],
        optionsUnitKerja: [],
        optionsJenisLayanan: [],
        formVisibility: false,
        optionsStatusVerifikasi: [
          {
            value: 1,
            label: "Disetujui",
          },
          {
            value: 2,
            label: "Berkas Tidak Lengkap",
          },
          {
            value: 3,
            label: "Tidak Memenuhi Syarat",
          },
        ],
        form: {
          id: null,
          id_instansi: null,
          id_unit_kerja: null,
          no_surat: null,
          tgl_surat: null,
          details: [],
          keterangan: null,
          status_hasil: null
        },
        loading: false,
        item: null,
        componentKey: 0,
        formDisabled: false
      };
    },
    computed: {},
    mounted() {},
    watch: {
      item: function (item) {
        this.form.id = null;
        this.form.id_instansi = null;
        this.form.id_unit_kerja = null;
        this.form.no_surat = null;
        this.form.tgl_surat = null;
        this.optionsInstansi = [];
        this.optionsUnitKerja = [];
        this.form.details = [];
        this.form.status_hasil = null,
        this.form.keterangan = null;

        if (item) {
          this.form.id = item.id;
          this.form.id_instansi = item.id_instansi.toString();
          this.form.id_unit_kerja = item.id_unit_kerja;
          this.form.no_surat = item.no_surat;
          this.form.tgl_surat = item.tgl_surat;
          const array = [];
          array.push(item.mst_instansi);
          this.optionsInstansi = array;
          const array2 = [];
          array2.push(item.mst_unit_kerja);
          this.optionsUnitKerja = array2;
          if (item.tracking_layanan.length > 0) {
            const lastTrackingLayanan = item.tracking_layanan[item.tracking_layanan.length - 1]
            if (lastTrackingLayanan.status == 3) {
              this.form.status_hasil = lastTrackingLayanan.status_hasil;
              this.form.keterangan = lastTrackingLayanan.keterangan;
            }
          }
  
          item.pelayanan_detail.forEach((element) => {
            const arrayPegawai = [];
            arrayPegawai.push({
              nip: element.d_identitas.nip,
              nama: element.d_identitas.nip + " - " + element.d_identitas.nama,
            });
            this.form.details.push({
              id: element.id,
              nip: element.nip,
              ref_jenis_layanan_id: element.ref_jenis_layanan_id,
              ref_persyaratan_pelayanan_id: element.ref_persyaratan_pelayanan_id,
              upload_file: element.upload_file,
              jenis_pelayanan_array: element.ref_jenis_pelayanan,
              petugas_array: arrayPegawai,
              optionsPgw: arrayPegawai,
              informasi_pegawai: element.d_identitas.v_detail_informasi_pegawai,
              upload_detail: element.pelayanan_detail_upload,
              is_deleted: 0,
              status_verifikasi: element.status_verifikasi,
            });
          });
        }
      },
    },
    methods: {
      async uploadFile(files, index, index2) {
        console.log(this.form.details[index]["upload_detail"]);
        const file = new FormData();
        file.append("files", files[0]);
        try {
          this.form.details[index]["upload_detail"][index2]["loadingFile"] = true;
          this.componentKey += 1;
          let { data } = await axios.post("/api/upload", file);
          this.form.details[index]["upload_detail"][index2]["upload_dokumen"] =
            data.url;
        } catch (error) {
          console.log(error);
          this.form.details[index]["upload_detail"][index2]["upload_dokumen"] =
            null;
        } finally {
          this.form.details[index]["upload_detail"][index2][
            "loadingFile"
          ] = false;
          this.componentKey += 1;
        }
      },
      async downloadFile(index, index2) {
        try {
          this.loading = true;
          var url = this.form.details[index]["upload_detail"][index2]["upload_dokumen"].split('/storage/');
          let response = await this.$store.dispatch("pelayanan/download", {
              path: url[1]
            }
          );
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            url[1].replace('dokumen-pegawai/', '')
          );
          document.body.appendChild(fileLink);
          fileLink.click();
        } catch (ex) {
          this.items = [];
        } finally {
          this.loading = false;
        }
      },
      deleteFile(index, index2) {
        this.form.details[index]["upload_detail"][index2]["upload_dokumen"] =
          null;
      },
      async onSearch(index, search) {
        // loading(true);
        await this.search(index, search, this);
        // loading(false);
      },
      search: _.debounce(async (index, search, vm) => {
        let options = await vm.$store.dispatch(
          "d_identitas/autocomplete",
          search
        );
        if (search) {
          requestAnimationFrame(() => {
            vm.form.details[index]["optionsPgw"] = options;
          });
        }
      }, 300),
      async searchLayanan(search) {
        let options = await this.$store.dispatch(
          "jenis_layanan/autocomplete",
          search
        );
        this.optionsJenisLayanan = options;
      },
      async verifikasiKenaikan() {
        const vm = this;
        Swal.fire({
          title: "Peringatan!",
          text: "Apa Anda yakin ingin verifikasi pegawai ini?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Verifikasi!",
        }).then(async (value) => {
          if(this.form.status_hasil != null){
            if (value.isConfirmed) {
              await this.$store.dispatch("pelayanan/verifikasi_i", {
                pelayanan_id: vm.form.id,
                status_hasil: vm.form.status_hasil,
                keterangan: vm.form.keterangan,
              });
              Swal.fire({
                title: "Ok!",
                text: "Verifikasi I Berhasil!",
                icon: "warning",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Ok!",
              }).then(() => {
                vm.componentKey += 1;
                requestAnimationFrame(() => {
                  this.$refs.observer.reset();
                });
                vm.$emit("done");
              });
            }
          }else{
            Swal.fire({
                title: "Ok!",
                text: "Pilihan Verifikasi belum dipilih!",
                icon: "warning",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Ok!",
            }).then(() => {
                vm.componentKey += 1;
            });
          }
        });
      },
      async submit() {
        const vm = this;
        try {
          this.loading = true;
          let { status } = this.item
            ? await this.$store.dispatch("pelayanan/update", {
                data: this.form,
                id: vm.item.id,
              })
            : await this.$store.dispatch("pelayanan/store", this.form);
          if (status >= 200 && status <= 202) {
            Swal.fire({
              title: "Sukses",
              text: "Data berhasil tersimpan!",
              icon: "success",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Tutup!",
            }).then(() => {
              if (!vm.item) {
                requestAnimationFrame(() => {
                  this.$refs.observer.reset();
                });
              }
              vm.$emit("done");
            });
          }
        } catch (x) {
          console.log(x);
        } finally {
          this.loading = false;
        }
      },
      showModalFormPilih(item) {
        this.$refs.formPilih.toggle(item, this.item);
      },
      populateInput(item, formDisabled) {
        this.searchLayanan("", this);
        this.item = item;
        this.formDisabled = formDisabled;
      },
      async onSearchInstansi(search, loading) {
        loading(true);
        await this.searchInstansi(search, this);
        loading(false);
      },
      searchInstansi: _.debounce(async (search, vm) => {
        let options = await vm.$store.dispatch(
          "master_instansi/autocomplete",
          search
        );
        if (search) vm.optionsInstansi = options;
      }, 300),
      async onSearchUnitKerja(search, loading) {
        loading(true);
        await this.searchUnitKerja(search, this);
        loading(false);
      },
      searchUnitKerja: _.debounce(async (search, vm) => {
        let options = await vm.$store.dispatch("master_unit_kerja/autocomplete", {
          search: search,
          id_instansi: vm.form.id_instansi,
          parent: "",
        });
        if (search) vm.optionsUnitKerja = options;
      }, 300),
      addDetails() {
        this.form.details.push({
          id: null,
          nip: null,
          ref_jenis_layanan_id: null,
          ref_persyaratan_pelayanan_id: null,
          upload_file: null,
          jenis_pelayanan_array: [],
          petugas_array: [],
          optionsPgw: [],
          informasi_pegawai: null,
          upload_detail: [],
          is_deleted: 0,
        });
      },
      deleteDetails(index) {
        this.form.details[index]["is_deleted"] = 1;
      },
      onChangesJenisLayanan(index, value) {
        console.log(index);
        console.log(value);
        this.form.details[index].ref_jenis_layanan_id = value.id;
        const me = this;
  
        me.form.details[index]["upload_detail"] = [];
        value.ref_persyaratan_pelayanan.forEach((element) => {
          me.form.details[index]["upload_detail"].push({
            id: null,
            upload_dokumen: null,
            ref_persyaratan_pelayanan_id: element.id,
            nama_dokumen: element.nama_dokumen,
            format_file: element.format_file,
            loadingFile: false,
          });
        });
        this.componentKey += 1;
      },
      onChangesPegawai(index, value) {
        this.form.details[index].informasi_pegawai =
          value.v_detail_informasi_pegawai;
        this.form.details[index].nip = value.nip;
        this.componentKey += 1;
      },
      CloseForm() {
        this.$emit("clicked", !this.formVisibility);
      },
    },
  };
</script>